import {Carousel} from 'react-carousel-minimal';

const images = [
    {
        image: 'img/gartic/20220924/album_2022-09-24_23-47-01.gif',
        caption: 'Sho in a bathroom stall'
    },
    {
        image: '/img/gartic/20220924/album_2022-09-24_23-47-45.gif',
        caption: 'A team of splatoon players fighting against enemies!'
    },
    {
        image: './img/gartic/20220924/album_2022-09-24_23-48-21.gif',
        caption: 'IYA F'
    },
    {
        image: './img/gartic/20220924/album_2022-09-24_23-48-55.gif',
        caption: 'pink shiny gorillas spinning on bananas'
    },
    {
        image: './img/gartic/20220924/album_2022-09-24_23-49-29.gif',
        caption: 'ANIMAL ON A BICYCLE'
    },
    {
        image: './img/gartic/20220924/album_2022-09-24_23-50-04.gif',
        caption: 'Dog woof woof bark innit'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-12-48.gif',
        caption: 'Das going crazy on the wall of china'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-13-23.gif',
        caption: 'Darkrai in the alley streets'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-14-05.gif',
        caption: 'Sho ctrl+alt+del their swimming pool ladder'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-14-39.gif',
        caption: 'Sho\'s hot tub stream'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-15-13.gif',
        caption: 'CRYING HAMSTER'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-15-46.gif',
        caption: 'Shame In Multiplied Proportions'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-47-54.gif',
        caption: 'Das and Sho wedding'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-48-45.gif',
        caption: 'Demon Vs Kon in Splatoon 3'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-49-34.gif',
        caption: 'Ren Zotto walking into Sho bathtub stream'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-50-24.gif',
        caption: 'sho and das smacking faces in the hot tub'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-51-13.gif',
        caption: 'DARS BEING A BULLY'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-52-03.gif',
        caption: 'Sho fighting everyone'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-53-08.gif',
        caption: 'dadbod gets shos feet pics (poggy)'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-53-57.gif',
        caption: 'A bread but he is a super star'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_00-54-47.gif',
        caption: 'my eyes are dry'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-07-43.gif',
        caption: 'Game mode: Knock-off'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-08-24.gif',
        caption: 'Game mode: Knock-off'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-09-07.gif',
        caption: 'Game mode: Knock-off'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-09-50.gif',
        caption: 'Game mode: Knock-off'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-10-32.gif',
        caption: 'Game mode: Knock-off'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-11-25.gif',
        caption: 'Game mode: Knock-off'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-12-11.gif',
        caption: 'Game mode: Knock-off'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-29-28.gif',
        caption: 'Game mode: Complement'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-30-10.gif',
        caption: 'Game mode: Complement'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-30-56.gif',
        caption: 'Game mode: Complement'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-31-38.gif',
        caption: 'Game mode: Complement'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-32-26.gif',
        caption: 'Game mode: Complement'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-33-15.gif',
        caption: 'Game mode: Complement'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-34-06.gif',
        caption: 'Game mode: Complement'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-49-59.gif',
        caption: 'Game mode: Complement'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-50-31.gif',
        caption: 'Game mode: Complement'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-51-04.gif',
        caption: 'Game mode: Complement'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-51-38.gif',
        caption: 'Game mode: Complement'
    },
    {
        image: './img/gartic/20220924/album_2022-09-25_01-52-19.gif',
        caption: 'Game mode: Complement'
    },
];

const captionStyle = {
    fontSize: '20px',
    textShadow: '1px 1px black',
    fontWeight: 'bold',
    color: 'white'

}

export default function Gartic() {
    return (
        <div className='App'>
            <h1>Gartic Phone - 2022-09-24</h1>
            <Carousel
                data={images}
                automatic={false}
                captionStyle={captionStyle}
                dots={true}
                thumbnails={false}
                captionPosition='top'
                style={{
                    margin: '40px 40px'
                }}
            />
        </div>
    );
}
