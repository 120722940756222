import { Routes, Route, Navigate } from "react-router-dom"
import Gartic from "./Gartic";

export default function App() {
  return (
      <div className='App'>
        <Routes>
          <Route path='/' element={<Navigate to='/gartic' replace={true} />} />
          <Route path='gartic' element={<Gartic />} />
        </Routes>
      </div>
  );
}